<template>
  <v-container class="mt-4 ml-2">
    <v-row justify="center">
      <v-col cols="12">
        <v-card class="shadowCard">
          <v-card-title class="text-subtitle-1">
            Validar Ingresos
            <v-spacer></v-spacer>
            <upload-excel-component :on-success="handleSuccess" :before-upload="beforeUpload" class="mr-2"/>
            <v-btn
              v-if="tableData.length"
              color="success"
              dark
              small
              class="ml-4"
              @click="procesarInformacion()"
            > Guardar </v-btn>
          </v-card-title>

          <v-row justify="end">
            <v-col cols="12" md="10" lg="6">
              <v-radio-group v-model="selectedSource" row>
                <v-radio
                  label="Seleccionar Fuente"
                  :value="null"
                ></v-radio>
                <v-radio
                  label="BBVA"
                  :value="'bbva'"
                ></v-radio>
                <v-radio
                  label="Tarjetas"
                  :value="'tarjetas'"
                ></v-radio>
                <!-- Puedes agregar más fuentes aquí fácilmente -->
              </v-radio-group>
            </v-col>
          </v-row>

          <v-col cols="12">
            <v-card flat>
              <v-alert
                v-if="!selectedSource"
                outlined
                type="info"
                prominent
              >
                Por favor, seleccione una fuente de datos antes de cargar un archivo.
              </v-alert>
              <v-alert outlined type="warning" prominent v-if="!tableData.length && !reportes.length">
                NO SE HA CARGADO NINGUN DOCUMENTO.
              </v-alert>

              <v-row justify="end">
                <v-col cols="12" md="10" lg="6">
                  <v-text-field
                    v-model="search"
                    dense
                    filled
                    label="Buscar"
                    append-icon="mdi-magnify"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-card v-if="tableData.length">
                <v-data-table
                  dense
                  :headers="currentHeaders"
                  :items="tableData"
                  item-key="name"
                  :page.sync="page"
                  @page-count="pageCount = $event"
                  fixed-header
                  hide-default-footer
                  :height="tamanioPantalla"
                  locale="es"
                  :search="search"
                >
                  <template v-slot:item.actions="{ item }">
                    <v-radio-group
                      v-model="item.aceptado"
                      row
                      @change="aceptarPago(item)"
                    >
                      <v-radio
                        label="Aceptar"
                        color="success"
                        :value="1"
                      ></v-radio>
                      <v-radio
                        label="Rechazar"
                        color="error"
                        :value="2"
                      ></v-radio>
                    </v-radio-group>
                  </template>
                </v-data-table>
              </v-card>
              <div class="text-center pt-2" v-if="tableData.length">
                <v-pagination v-model="page" :length="pageCount"></v-pagination>
              </div>
            </v-card>
          </v-col>
        </v-card>
      </v-col>
    </v-row>

    <!-- Dialog de carga -->
    <Alerta v-if="respuestaAlerta" :parametros="parametros"/>
    <carga v-if="cargar"/>
  </v-container>
</template>

<script>
import axios from 'axios';
  import UploadExcelComponent from '@/components/UploadExcel.vue'

	import { mapGetters} from 'vuex'
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'
  import filtrosRolTeachers    from '@/mixins/filtrosRolTeachers'


  export default {

  	components:{
      Alerta,
      carga,
    	UploadExcelComponent
    },

    mixins: [ validarErrores, funcionesExcel, filtrosRolTeachers ],

	  data() {
	    return {
	    	// Alertas
	    	parametros:{
	        dialogError: false,
	        mensaje: '',
	        color: ''
	      },

        ingresos:[],

	      respuestaAlerta:false,
	      loader: true,
	      cargar: false,

	      page: 1,
	      pageCount: 0,
	      itemsPerPage: 20,

	      search:'',
        /* selectedSource: 'tarjetas', // Default to tarjetas */


        // Configura tus fuentes de manera más escalable
        sourceConfigurations: {
          bbva: {
            headers: [
              { text: 'Día', value: 'dia' },
              { text: 'Concepto / Referencia', value: 'concepto' },
              { text: 'Cargo', value: 'cargo' },
              { text: 'Abono', value: 'abono' },
              { text: 'id ingreso', value: 'id_ingreso' },
              { text: 'Alumno', value: 'alumno' },
              { text: 'Monto pagado', value: 'monto_pagado' },
              { text: 'Actions', value: 'actions' }
              // ... otros headers de BBVA
            ],
            processData(value, me) {
              // Lógica específica para procesar datos de BBVA
              value.dia = me.excelDateToJSDate(value['Día'])
              value.concepto = value['Concepto / Referencia']
              value.cargo = value['cargo']
              value.abono = value['Abono']
              // Agrega más transformaciones según sea necesario
              return value
            },
            matchIncome(value, ingresos) {
              // Lógica para encontrar ingresos coincidentes para BBVA
              let primerSeparador = value.concepto.split('/')
              let segundoSeparador = primerSeparador[primerSeparador.length-1].trim().split(' ')
              let palabraBuscar = segundoSeparador[0]

              //Existe ingreso
              return ingresos.find(el => el.aut_clave_rastreo == palabraBuscar)
            }
          },
          tarjetas: {
            headers: [
              { text: 'Número de comercio', value: 'num_comercio' },
              { text: 'Nombre de Comercio', value: 'nom_comercio' },
              { text: 'Fecha de archivo', value: 'fecha_archivo' },
              { text: 'Hora de archivo', value: 'hora_archivo' },
              { text: 'Monto Original de la Transacción', value: 'monto_original' },
              { text: 'Moneda del Monto Original de Transacción', value: 'moneda_monto' },
              { text: 'Tipo de transacción', value: 'tipo_transaccion' },
              { text: 'Método de Pago', value: 'metodo_pago' },
              { text: 'Número de tarjeta', value: 'num_tarjeta' },
              { text: 'ID de la transacción', value: 'id_transaccion' },
              { text: 'Código de Autorización', value: 'codigo_auth' },
              { text: 'id ingreso', value: 'id_ingreso' },
              { text: 'Alumno', value: 'alumno' },
              { text: 'Monto pagado', value: 'monto_pagado' },
              { text: 'Actions', value: 'actions' }
              // ... otros headers de tarjetas
            ],
            processData(value, me) {
              // Eliminar comillas simples de los valores
              for (let key in value) {
                  if (typeof value[key] === 'string') {
                    value[key] = value[key].replace(/'/g, '')
                  }
                }
              // Lógica específica para procesar datos de tarjetas
              value.num_comercio = value['Número de comercio']
              value.nom_comercio = value['Nombre de Comercio']
              value.fecha_archivo = me.excelDateToJSDate(value['Fecha de archivo'])
              value.hora_archivo = value['Hora de archivo']
              value.monto_original = value['Monto Original de la Transacción']
              value.moneda_monto = value['Moneda del Monto Original de Transacción']
              value.tipo_transaccion = value['Tipo de transacción']
              value.metodo_pago = value['Método de Pago']
              value.num_tarjeta = value['Número de tarjeta']
              value.id_transaccion = value['ID de la transacción']
              value.codigo_auth = value['Código de Autorización']
              // Agrega más transformaciones
              return value
            },
            matchIncome(value, ingresos) {
              // Lógica para encontrar ingresos coincidentes para tarjetas
              return ingresos.find(el => el.aut_clave_rastreo == value.codigo_auth)
            }
          }
          // Puedes agregar más fuentes aquí fácilmente
          // nuevaFuente: { ... }
        },
        selectedSource: null,

        tableData: [],  // DATOS A MOSTRAR
        reportes: [],  // TABLA REPORTES

        alerta: { activo: false, texto: '', color: 'error' },
        overlay: false,

        codigos: []
        }
    },

	  async created(){
      this.initialize()
	  },

	  computed: {
    ...mapGetters('login', ['getdatosUsuario']),
    tamanioPantalla() {
      return this.$vuetify.breakpoint.height - 380
    },
    currentHeaders() {
      return this.selectedSource
        ? this.sourceConfigurations[this.selectedSource].headers
        : []
    }
  },

	  methods: {

      initialize () {
      	this.cargar = true
        this.ingresos = []
        return this.$http.get('ingresos.rastreo.all').then(response=>{
          this.ingresos = response.data
          console.log('ingresos',this.ingresos)
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      comparar(){

        for( const i in this.tableData){

          const { concepto } = this.tableData[i]

          let primerSeparador = concepto.split('/')

          let segundoSeparador = primerSeparador[primerSeparador.length-1].trim().split(' ')

          let palabraBuscar = segundoSeparador[0]
          console.log(palabraBuscar)
          const existeIngreso = this.ingresos.find( el => el.aut_clave_rastreo == palabraBuscar )

          this.tableData[i]['id_ingreso'] = existeIngreso ? existeIngreso.id_ingreso : null
          this.tableData[i].alumno = existeIngreso ? existeIngreso.alumno : null
          this.tableData[i].monto_pagado = existeIngreso ? existeIngreso.monto_pagado : null

          console.log('separador', existeIngreso )


        }

      },

      aceptarPago(item){
        console.log(item)

        const payload = {
          id_ingreso: item.id_ingreso,
          aceptado: item.aceptado
        }

        this.$http.put('ingresos.rastreo.update/' + item.id_ingreso, payload ).then(response=>{
            this.validarSuccess( response.data.message )
          	this.initialize()
        		this.close()
	        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },



	    obtenerDatos(results) {
        if (!this.selectedSource) return

        const config = this.sourceConfigurations[this.selectedSource]

        const Temporal = results.map(value => {
          // Procesa el valor según la configuración de la fuente
          value = config.processData(value, this)

          // Busca ingresos coincidentes
          const existeIngreso = config.matchIncome(value, this.ingresos)

          // Asigna datos de ingreso
          value.id_ingreso = existeIngreso ? existeIngreso.id_ingreso : null
          value.alumno = existeIngreso ? existeIngreso.alumno : null
          value.monto_pagado = existeIngreso ? existeIngreso.monto_pagado : null

          value.id_alumno = 0
          return value
        })

        this.tableData = Temporal

    },


      beforeUpload(file) {
	      const isLt1M = file.size / 1024 / 1024 < 1
	      if (isLt1M) {
	        return true
	      }
	      this.$message({
	        message: 'Please do not upload files larger than 1m in size.',
	        type: 'warning'
	      })

	      return false
	    },

	    handleSuccess({results, header }) {
	      this.obtenerDatos(results);
	    },


      excelDateToJSDate(serial) {
        if (serial) {
          // Eliminar cualquier espacio en blanco y convertir a cadena
          serial = String(serial).trim()

          // Intentar convertir directamente si es un número
          if (!isNaN(serial)) {
            // Método original para fechas numéricas de Excel
            var utc_days = Math.floor(serial - (25567 + 1));
            var utc_value = utc_days * 86400;
            var date_info = new Date(utc_value * 1000);

            const fecha = (new Date(date_info - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
            return fecha;
          }

          // Intentar parsear diferentes formatos de fecha
          try {
            // Formatos comunes de fecha
            const formatosFecha = [
              /^\d{1,2}\/\d{1,2}\/\d{2,4}$/, // dd/mm/yyyy o mm/dd/yyyy
              /^\d{2,4}-\d{1,2}-\d{1,2}$/, // yyyy-mm-dd o dd-mm-yyyy
              /^\d{1,2}\.\d{1,2}\.\d{2,4}$/ // dd.mm.yyyy
            ];

            // Verificar si la fecha coincide con alguno de los formatos
            for (let formato of formatosFecha) {
              if (formato.test(serial)) {
                // Intentar crear una fecha con Date.parse()
                const fecha = new Date(serial);
                if (!isNaN(fecha)) {
                  return fecha.toISOString().substr(0, 10);
                }
              }
            }

            // Si no se puede parsear, devolver null
            return null;
          } catch (error) {
            console.error('Error al parsear fecha:', serial, error);
            return null;
          }
        }

        return null;
      },

       excelSerialToTime(serial) {
        if (serial) {
            /*
            Calcular la hora
            */
            var fractional_day = serial - Math.floor(serial) + 0.0000001;
            var total_seconds = Math.floor(86400 * fractional_day);
            var seconds = total_seconds % 60;
            total_seconds -= seconds;

            var hours = Math.floor(total_seconds / (60 * 60));
            var minutes = Math.floor(total_seconds / 60) % 60;

            const hora = (hours < 10 ? ('0' + hours) : hours) + ':' + (minutes < 10 ? ('0' + minutes) : minutes) + ':' + (seconds < 10 ? ('0' + seconds) : seconds);

            return hora;
        }

        return null;
        },

	    procesarInformacion( ){
	    	this.cargar = true
    		this.$http.post('conmutador.cargar.datos.entradas', this.tableData ).then(response=>{
    			this.tableData = []
    			this.validarSuccess( response.data.message )
    			this.cargar = false
    		}).catch(error=>{
    			this.validarError( error.response.data.message )
    		}).finally( () =>{ this.cargar = false } )
	    },

	  }
	}
</script>



